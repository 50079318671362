<template>
<div class="content" v-if="!isPaySuccess">
    <h1 style="
    align-self: center;
    text-align: center;
    color: var(--text-color-1);
">"Ödemeniz henüz tamamlanmadı. Lütfen hizmetlerimizi kullanmaya devam edebilmek için <a href="https://yeniegitimsistemi.net">yeniegitimsistemi.net</a> adresine giderek işleminizi tamamlayın."</h1>
</div>
<slot v-if="isPaySuccess" />
</template>

<script>
export default {
    data() {
        return {
            isPaySuccess: false
        }
    },
    created() {
        const checkUser = this.$cookies.get("UserData");
        if (checkUser!= null && checkUser != undefined && checkUser != "undefined") {
            this.isPaySuccess = !(checkUser.payType == "NOT_PAYMENT");
        } else{
          this.isPaySuccess = true;
        }
    }
}
</script>
