<template>
<div class="video-doc">
    <iframe :src="pdfurl" frameborder="0" />
</div>
</template>

<script>
export default {
    data() {
        return {
            pdfurl: '',
            elementId: '',
        }
    },
    watch: {
        '$route.params.elementId': function (newData, oldData) {
            if (oldData != null && this.$route.name == 'document') {
                this.init();
            }
        }
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.elementId = this.$route.params.elementId;
            this.getData();
        },
        getData() {
            this.$center.emit('Loading', 'show');
            this.$http.get("Student/Element/Document/" + this.elementId).then(x => {
                var url=x.data.data.url;
                console.log(url);
                this.pdfurl = x.data.data.url;
                this.$center.emit('TitleChanged', x.data.data.title);
                this.$center.emit('Loading', 'hide');
            }).catch(error => {
                 console.log(error);
                this.$center.emit('Loading', 'hide');
            });
        }
    }
}
</script>
